
@use "sass:map";

@import "./../scss/required";

@import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// this is a temporary class. please do not copy/propagate this
.row-collections {
    .img-responsive {
        overflow: hidden;
        border: $border-width solid $gray-300;
        border-radius: $border-radius;
    }
}

.section-title * {
    display: inline-block;
}

.vue-recycle-scroller {
    padding: calc(2 * $spacer) 0;
}

.feed-item {
    width: 336px;
    margin: 0 auto map.get($spacers, 4) auto;
    padding-bottom: map.get($spacers, 2);
    background-color: $white;
    border-radius: $border-radius;
    border: $border-width solid transparent;

    .item-product {
        border: none;
    }

    &:hover {
        border-color: $gray-200;
    }
}

.item-header {
    display: flex;
    gap: map.get($spacers, 2);
    align-items: center;
    padding: $spacer;

    .activity {
        max-width: 280px;
        max-height: 3.6em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: $font-size-sm;

        a {
            font-weight: $font-weight-bold;
        }
    }
}
.vue-recycle-scroller__item-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: map.get($spacers, 4);
    justify-content: space-around;
}

.slider {
    margin-bottom: $spacer;

    .slider-indicators {
        bottom: 0;
    }
}
